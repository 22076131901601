"use client";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { addToCart as addProductToCart } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

import useLocation from "../../useLocation";
import { optimisticAddToCart } from "../utils/optimisticUpdate";
import type { CartItem } from "./useItems";

// export default function useAddToCart() {
//   const queryClient = useQueryClient();
//   const customerToken = usePersistedStore((state) => state.token);
//   const { currency } = useLocation();
//   const addToCart = useMutation({
//     mutationKey: ["addToCart"],
//     mutationFn: async (variables: { itemId: string; cartItem: CartItem }) =>
//       addProductToCart(variables, customerToken),

//     onMutate: async (variables) => {
//       await queryClient.cancelQueries({ queryKey: ["selection"] });

//       // Snapshot the previous value
//       const oldData = queryClient.getQueryData(["selection"]);

//       // // Optimistically update to the new value
//       queryClient.setQueryData(["selection"], (oldCart: any) =>
//         optimisticAddToCart(
//           oldCart,
//           {
//             itemId: variables.itemId,
//             cartItem: variables.cartItem,
//           },
//           currency || ""
//         )
//       );

//       return { oldData };
//     },
//     onError: async (error, _variables, context) => {
//       return queryClient.setQueryData(["selection"], context?.oldData);
//     },
//     onSettled: async (context) => {
//       queryClient.setQueryData(["selection"], context);
//       if (!queryClient.isMutating({ mutationKey: ["addToCart"] })) {
//         return queryClient.invalidateQueries({ queryKey: ["selection"] });
//       }
//     },
//   });
//   return { addToCart };
// }

export default function useAddToCart() {
  const queryClient = useQueryClient();
  if (!queryClient) console.error("No queryClient");

  const customerToken = usePersistedStore((state) => state.token);
  const { currency } = useLocation();
  const addToCart = useMutation({
    mutationKey: ["addToCart"],
    mutationFn: async (variables: { itemId: string; cartItem?: CartItem }) => {
      await queryClient.cancelQueries({ queryKey: ["selection"] });
      return await addProductToCart(variables, customerToken);
    },

    onMutate: async (variables) => {
      const oldData = queryClient.getQueryData(["selection"]);
      if (!oldData) queryClient.invalidateQueries({ queryKey: ["selection"] });

      // // Optimistically update to the new value
      queryClient.setQueryData(["selection"], (oldCart: any) =>
        optimisticAddToCart(
          oldCart,
          {
            itemId: variables.itemId,
            cartItem: variables.cartItem,
          },
          currency || ""
        )
      );

      return { oldData };
    },
    onError: async (error, _variables, context) => {
      return queryClient.setQueryData(["selection"], context?.oldData);
    },
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ["selection"] });
    },
  });
  return { addToCart };
}

export type AddToCart = ReturnType<typeof useAddToCart>["addToCart"];
