"use client";
import config from "@/config";

import type { ProductCard } from "../centra/formatters/formatProductCards/formatProductCard";
import usePersistedStore from "../stateManagement/persistedState/persistedStore";

export type ProductCardWishlist = {
  active: boolean;
  toggle: () => void;
};
type SingleVariantCard = {
  id: string;
  wishlist?: ProductCardWishlist;
  [index: string]: unknown;
};

type MultiVariantCard = {
  default: string;
  variants: {
    [index: string]: {
      id?: string;
      wishlist?: ProductCardWishlist;
      [index: string]: unknown;
    };
  };
};

type UseWishlist = typeof useWishlistLocal;
type AddWishlist = (products: ProductCard[]) => ProductCard[];

const variantCardWishlistBuilder =
  <T extends SingleVariantCard[]>(
    includes: (id: string) => boolean,
    toggle: (id: string) => () => void
  ) =>
  (products: T) =>
    products.map((product) => ({
      ...product,
      wishlist: {
        active: includes(product.id),
        toggle: toggle(product.id)
      }
    }));

const productCardWishlistBuilder =
  <T extends MultiVariantCard[]>(
    includes: (id: string) => boolean,
    toggle: (id: string) => () => void
  ) =>
  (products: T) =>
    products.map((product) => {
      Object.keys(product.variants).forEach((variant) => {
        let v = product.variants[variant];
        if (v && v.id) {
          v.wishlist = { active: includes(v.id), toggle: toggle(v.id) };
        }
      });
      return product;
    });

const useWishlistLocal = () => {
  const ids = usePersistedStore((store) => store.wishlistIds).filter(
    (id) => !!id
  );

  const removedIds = usePersistedStore((store) => store.wishlistRemoved);
  const addToWishlist = usePersistedStore((store) => store.wishlistAddId);

  const removeFromWishlist = usePersistedStore(
    (store) => store.wishlistRemoveId
  );
  const restoreLastRemoved = usePersistedStore(
    (store) => store.wishlistRestoreLastRemoved
  );
  const clearAll = usePersistedStore((store) => store.wishlistClearAll);

  const clearRemoved = usePersistedStore((store) => store.wishlistClearRemoved);

  const isOnWishlist = (id: string) => {
    return ids.includes(id);
  };

  const toggle = (id: string) => {
    return () => {
      if (isOnWishlist(id)) {
        return removeFromWishlist(id);
      } else {
        return addToWishlist(id);
      }
    };
  };

  const addWishlistToVariantCards = variantCardWishlistBuilder(
    isOnWishlist,
    toggle
  );

  const addWishlistToProductCards = productCardWishlistBuilder(
    isOnWishlist,
    toggle
  );

  return {
    ids,
    removedIds,
    add: addToWishlist,
    remove: removeFromWishlist,
    isOnWishlist,
    toggle,
    restoreLastRemoved,
    clearAll,
    clearRemoved,
    addWishlistToVariantCards,
    addWishlistToProductCards:
      config.variantsPerDisplay === "multi"
        ? (addWishlistToProductCards as unknown as AddWishlist)
        : (addWishlistToVariantCards as unknown as AddWishlist)
  };
};

const useWishlistCentra = () => {};

export default config.wishlist === "centra"
  ? (useWishlistCentra as UseWishlist)
  : useWishlistLocal;
